<template>
  <el-dialog
    title="指令下发"
    :visible.sync="visible"
    width="50%"
    @close="close"
  >
    <div class="siteId flex" style="margin: 25px 65px">
      <!-- <list-info label="站点ID" :value1="siteId"></list-info> -->
      <span style="flex: 1; color: #3274ec; line-height: 32px">站点ID</span>
      <span style="flex: 9; color: #3274ec">
        <el-tag>
          {{ siteId }}
        </el-tag>
      </span>
    </div>
    <el-form :model="form2" ref="form2" :rules="rules" class="flex flex-start">
      <el-form-item
        prop="maxPower"
        label="场站最大功率"
        label-width="140px"
        style="flex: 3"
      >
        <el-input v-model="form2.maxPower"></el-input>
      </el-form-item>
      <el-form-item
        prop="tolerance"
        label="容差"
        label-width="140px"
        style="flex: 3"
      >
        <el-input v-model="form2.tolerance"></el-input>
      </el-form-item>
      <el-form-item style="flex: 1">
        <el-button
          class="btn-purple"
          @click="toleranceIssued('form2')"
          v-preventReClick
          >请求下发</el-button
        >
      </el-form-item>
    </el-form>
    <el-form :model="rateForm" ref="rateForm" :rules="rules">
      <el-form-item
        prop="rateId"
        label="费率标准"
        label-width="140px"
        style="flex: 3"
      >
        <el-select v-model="rateForm.rateId" placeholder="请选择">
          <el-option
            v-for="item in rateOptions"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        prop="date"
        label="生效日期"
        label-width="140px"
        style="flex: 3"
      >
        <el-date-picker
          v-model="rateForm.date"
          type="datetime"
          placeholder="选择日期"
          value-format="yyyy-MM-dd HH:mm"
          format="yyyy-MM-dd HH:mm"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item style="flex: 1">
        <el-button
          class="btn-purple"
          @click="rateIssued('rateForm')"
          v-preventReClick
          >请求下发</el-button
        >
      </el-form-item>
    </el-form>

    <el-form :model="form4" ref="form4" :rules="rules" class="flex flex-start">
      <el-form-item
        prop="usePower"
        label="限电允许用电功率"
        label-width="140px"
        style="flex: 3"
      >
        <el-input v-model="form4.usePower"></el-input>
      </el-form-item>
      <el-form-item
        prop="maxChargePower"
        label="限电最大充电功率"
        label-width="140px"
        style="flex: 3"
      >
        <el-input v-model="form4.maxChargePower"></el-input>
      </el-form-item>
      <el-form-item style="flex: 1">
        <el-button
          class="btn-purple"
          @click="powerIssued('form4')"
          v-preventReClick
          >请求下发</el-button
        >
      </el-form-item>
    </el-form>

    <el-form :model="form3" ref="form3" :rules="rules" inline>
      <el-row style="width: 100%">
        <el-col :span="12">
          <el-form-item
            prop="electricityDate"
            label="限电日期"
            label-width="140px"
          >
            <el-date-picker
              v-model="form3.electricityDate"
              type="date"
              placeholder="选择日期"
              value-format="yyyy-MM-dd"
              format="yyyy-MM-dd"
              style="width: 91% !important"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
        <el-col :span="8"></el-col>
        <el-col :span="4"
          ><el-form-item>
            <el-button
              class="btn-purple"
              @click="dateIssued('form3')"
              v-preventReClick
              >请求下发</el-button
            >
          </el-form-item></el-col
        >
      </el-row>
    </el-form>
    <el-form :model="form5" ref="form5" :rules="rules" inline>
      <el-row style="width: 100%">
        <el-col :span="12">
          <el-form-item
            prop="changePassword"
            label="修改密码"
            label-width="140px"
            style="width: 106% !important"
          >
            <el-input v-model="form5.changePassword"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="9"> </el-col>
        <el-col :span="3">
          <el-form-item>
            <el-button
              class="btn-purple"
              @click="changePwdIssued('form5')"
              v-preventReClick
              >请求下发</el-button
            >
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <el-form-item>
        <el-button class="btn-purple" @click="rateIssued()" v-preventReClick
          >密码查询</el-button
        >
      </el-form-item> -->
    </el-form>
    <!-- <div class="password-box flex flex-start">
      <div class="check-password">
        <el-button class="btn-purple" @click="passwordQuery()" v-preventReClick
          >密码查询</el-button
        >
      </div>
      <div class="show-password"></div>
    </div> -->
  </el-dialog>
</template>
<script>
// import listInfo from "../../../components/list-info.vue";
import { rateQuery } from "@/api/rateList";
import { cmdRequest } from "@/api/siteList";
// import i18n from "@/lang";
export default {
  // components: { listInfo },
  props: {},
  data() {
    return {
      visible: false,
      siteId: "",
      rateForm: {
        rateId: "",
        date: "",
      },
      form2: {
        tolerance: "",
        maxPower: "",
      },
      form3: {
        electricityDate: "",
      },
      form4: {
        usePower: "",
        maxChargePower: "",
      },
      form5: {},
      rateOptions: [],
      form1Rules: {},
      rules: {
        rateId: [{ required: true, message: "请输入", trigger: "change" }],
        date: [{ required: true, message: "请输入", trigger: "change" }],
        usePower: [{ required: true, message: "请输入", trigger: "blur" }],
        maxChargePower: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        maxPower: [{ required: true, message: "请输入", trigger: "blur" }],
        tolerance: [{ required: true, message: "请输入", trigger: "blur" }],
        changePassword: [
          { required: true, message: "请输入", trigger: "blur" },
        ],
        electricityDate: [
          { required: true, message: "请输入", trigger: "change" },
        ],
      },
    };
  },
  mounted() {},
  methods: {
    passwordQuery() {},
    getRate() {
      rateQuery()
        .then((res) => {
          console.log(res.data.data.list, "rateOptions");
          this.rateOptions = res.data.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    open(row) {
      this.visible = true;

      this.siteId = row.siteId;
      console.log(row, row.siteId);
      this.getRate();
    },
    close() {
      this.visible = false;
    },
    rateIssued(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.rateForm.date);
          let rate = {};
          this.rateOptions.forEach((v) => {
            if (v.id == this.rateForm.rateId) {
              rate = v;
            }
          });
          console.log(rate.sharpTimeMonth);
          let rateTime = JSON.parse(
            JSON.stringify(rate.rateTime).replace(/timeSection/g, "periodType")
          );
          rateTime = JSON.parse(rateTime.replace(/status/g, "controlMode"));

          let data1 = {
            fee1: rate.sharpTimePrice,
            fee2: rate.highTimePrice,
            fee3: rate.normalTimePrice,
            fee4: rate.lowTimePrice,
            fee5: rate.highSharpTimePrice,
            effectiveTime: this.rateForm.date,
            topPeakMonths: rate.sharpTimeMonth.split(","),
            periodFeeConfigs: rateTime,
          };
          let data = {
            cmd: "setFeeConfig",
            data: data1,
          };
          console.log(rate, "rate");
          let name = "设置费率";
          this.getIssued(data, name);
        }
      });
    },
    powerIssued(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form4);
          let data = {
            cmd: "setDischargeChargePower",
            data: {
              dischargePower: this.form4.usePower,
              chargePower: this.form4.maxChargePower,
            },
          };
          let name = "设置限电允许用电功率和限电最大充电功率";
          this.getIssued(data, name);
        }
      });
    },
    toleranceIssued(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form2);
          let data = {
            cmd: "setToleranceMaxPower",
            data: {
              maxPower: this.form2.maxPower,
              tolerance: this.form2.tolerance,
            },
          };
          let name = "设置容差和场站最大功率";

          this.getIssued(data, name);
        }
      });
    },
    dateIssued(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form3);
          let data = {
            cmd: "setPowerOutageDate",
            data: {
              powerOutageDate: this.form3.electricityDate,
            },
          };
          let name = "设置限电日期";
          this.getIssued(data, name);
        }
      });
    },
    changePwdIssued(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form5);
          let data = {
            cmd: "setPasswd",
            data: {
              newPasswd: this.form5.changePassword,
            },
          };
          let name = "密码修改";
          this.getIssued(data, name);
        }
      });
    },
    getIssued(listValue, name) {
      console.log(listValue, "listValue");
      let obj = {
        siteId: this.siteId,
        name: name,
        jsonData: JSON.stringify(listValue),
      };
      cmdRequest(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: "请求下发成功",
          });
        } else {
          this.$message({
            type: "error",
            message: res.data.message,
          });
        }
        console.log(res);
      });
    },
  },
};
</script>
<style lang="stylus" scoped>
/deep/ .el-input{
  width 100% !important
}
/deep/ .el-select{
  width 100% !important
}
/deep/ .el-select .el-input{
  width 100% !important
}
// /deep/ .el-button{
//   margin-left: 30px !important
// }
/deep/ .el-form{
  display: flex
  justify-content: flex-start
}
/deep/ .el-form-item{
  margin: 0 30px 22px 0 !important
}
/deep/ .el-dialog, .el-message-box{
  min-width 957px !important
}
// /deep/ .el-dialog__body{
//   padding: 0 20px !important
// }
</style>
