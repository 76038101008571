<template>
  <el-dialog
    :title="type === 1 ? '新增站点信息' : '修改站点信息'"
    :visible.sync="visible"
    width="750px"
    @close="close"
  >
    <el-form :model="customerForm" ref="customerForm" :rules="rules">
      <el-row :gutter="20">
        <el-col :span="11"
          ><el-form-item
            prop="installedCapacity"
            label="装机容量"
            label-width="100px"
          >
            <el-input v-model="customerForm.installedCapacity" disabled>
              <template slot="append">kWh</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="11"
          ><el-form-item
            prop="installedPower"
            label="装机功率"
            label-width="100px"
          >
            <el-input v-model="customerForm.installedPower" disabled>
              <template slot="append">kW</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="11"
          ><el-form-item
            prop="dailyChargeTimes"
            label="日充放次数"
            label-width="100px"
          >
            <el-select v-model="customerForm.dailyChargeTimes" disabled>
              <el-option
                v-for="item in chargeOptions"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="11"
          ><el-form-item
            prop="decayRate"
            label="环境利用率"
            label-width="100px"
          >
            <el-input v-model="customerForm.envUseRatio" disabled>
              <template slot="append">%</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="11"
          ><el-form-item prop="investment" label="投资额" label-width="100px">
            <el-input
              placeholder="请输入内容"
              v-model="customerForm.investment"
            >
              <template slot="append">元</template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer" v-if="type != 3">
      <el-button
        class="btn-blue"
        type="primary"
        style="color: #000"
        @click="onSubmit('customerForm')"
        >{{
          type === 1 ? $t("addDevice.add") : $t("addDevice.edit")
        }}</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { siteAdd, siteEdit } from "@/api/siteList.js";
import { rateQuery } from "@/api/rateList";
import { customerQuery } from "@/api/customer";
import { deepClone } from "@/utils/deepClone";
import { provinceAndCityData, CodeToText } from "element-china-area-data";
import i18n from "@/lang";
export default {
  data() {
    return {
      visible: false,
      type: 1, //1新增 2编辑
      customerForm: {
        jsonRatioData: [
          {
            customerId: "",
            ratio: 0,
          },
        ],
        address: "",
        service: ["", ""],
        startTime: "",
        endTime: "",
        selectedOptions: [],
        soc: 0,
        totalRunDay: 0,
        totalEarnings: 0,
        investment: 0,
        decayRate: 0,
        envUseRatio: 0,
      },
      rules: {
        name: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        siteId: [
          {
            required: true,
            message: "不为空",
            trigger: ["blur"],
          },
        ],
        allotWay: [
          {
            required: true,
            message: "不为空",
            trigger: ["change"],
          },
        ],
        rateId: [
          {
            required: true,
            message: "不为空",
            trigger: ["change"],
          },
        ],
        customerId: [
          {
            required: true,
            message: "不为空",
            // trigger: ["change"],
          },
        ],
        ratio: [
          {
            required: true,
            message: "不为空",
            // trigger: ["blur", "change"],
          },
        ],
      },
      options: provinceAndCityData,
      rateOptions: [],
      customerOptions: [],
      allotWayOptions: [
        {
          id: 1,
          name: "电价折扣",
        },
        {
          id: 2,
          name: "投资分红",
        },
      ],
      chargeOptions: [
        {
          id: 1,
          name: "一充一放",
        },
        {
          id: 2,
          name: "两充两放",
        },
        {
          id: 3,
          name: "三充三放",
        },
      ],
    };
  },
  mounted() {},
  methods: {
    handleChange() {
      var loc = "";
      for (let i = 0; i < this.customerForm.selectedOptions.length; i++) {
        loc += CodeToText[this.customerForm.selectedOptions[i]];
        if (i != this.customerForm.selectedOptions.length - 1) {
          loc += ",";
        }
      }
      var reg1 = new RegExp("省", "g"); // 加'g'，删除字符串里所有的"a"
      var reg2 = new RegExp("市", "g");
      var a1 = loc.replace(reg1, "");
      var a2 = a1.replace(reg2, "");
      this.customerForm.address = a2;
    },
    getRate() {
      rateQuery()
        .then((res) => {
          console.log(res.data.data.list);
          this.rateOptions = res.data.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getCustomer() {
      customerQuery()
        .then((res) => {
          console.log(res.data.data.list);
          this.customerOptions = res.data.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    addDataList() {
      let info = {
        customerId: 0,
        ratio: 0,
      };
      this.customerForm.jsonRatioData.push(info);
    },
    deleteDataList(index) {
      this.customerForm.jsonRatioData.splice(index, 1);
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(JSON.stringify(this.customerForm.jsonRatioData));
          let obj = {
            siteId: this.customerForm.siteId,
            name: this.customerForm.name,
            rateId: this.customerForm.rateId,
            allotWay: this.customerForm.allotWay,
            jsonRatioData: JSON.stringify(this.customerForm.jsonRatioData),
            address: this.customerForm.address,
            serviceBeginTime: this.customerForm.startTime,
            serviceEndTime: this.customerForm.endTime,
            dailyChargeTimes: this.customerForm.dailyChargeTimes,
            installedPower: this.customerForm.installedPower,
            installedCapacity: this.customerForm.installedCapacity,
            totalEarnings: this.customerForm.totalEarnings,
            totalRunDay: this.customerForm.totalRunDay,
            soc: this.customerForm.soc,
            online: this.customerForm.online,
            status: this.customerForm.status,
            totalChargeNum: this.customerForm.totalChargeNum,
            totalDischargeNum: this.customerForm.totalDischargeNum,
            investment: this.customerForm.investment,
            decayRate: this.customerForm.decayRate / 100,
            envUseRatio: this.customerForm.envUseRatio / 100,
          };
          console.log(obj);
          if (this.type === 1) {
            this.addInfo(obj);
          } else if (this.type === 2) {
            this.editInfo(obj);
          }
        }
      });
    },
    addInfo(obj) {
      console.log("add", obj);
      obj.totalEarnings = 0;
      obj.totalRunDay = 0;
      obj.soc = 0;
      obj.online = 0;
      obj.status = 0;
      obj.totalChargeNum = 0;
      obj.totalDischargeNum = 0;
      siteAdd(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: i18n.t("prompt.operateSuccessfully"),
          });
          this.$emit("refresh");
          this.visible = false;
        } else {
          this.$message({
            type: "error",
            message: i18n.t("prompt.operationFailure") + res.data.message,
          });
        }
      });
    },
    editInfo(obj) {
      obj.id = this.customerForm.id;
      console.log("edit", obj);

      siteEdit(obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            type: "success",
            message: i18n.t("prompt.operateSuccessfully"),
          });
          this.$emit("refresh");
          this.visible = false;
        } else {
          this.$message({
            type: "error",
            message: i18n.t("prompt.operationFailure") + res.data.message,
          });
        }
      });
    },
    open(type, row) {
      console.log(typeof type, type, row);
      this.getRate();
      this.getCustomer();
      this.visible = true;
      this.type = type;
      this.customerForm = {
        jsonRatioData: [
          {
            customerId: "",
            ratio: 0,
          },
        ],
      };
      if (type === 1) {
        this.viewDisable = false;
      } else if (this.type === 2) {
        this.customerForm = deepClone(row);
        this.customerForm.jsonRatioData = JSON.parse(row.jsonRatioData);
        // console.log(row.serviceBeginTime.substr(0, 10));
        this.$set(
          this.customerForm,
          "startTime",
          row.serviceBeginTime.substr(0, 10)
        );
        this.$set(
          this.customerForm,
          "endTime",
          row.serviceEndTime.substr(0, 10)
        );
        this.customerForm.decayRate = this.customerForm.decayRate * 100;
        this.customerForm.envUseRatio = this.customerForm.envUseRatio * 100;
      } else if (type === 3) {
        this.customerForm = deepClone(row);
        this.customerForm.jsonRatioData = JSON.parse(
          this.customerForm.jsonRatioData
        );
        this.$set(
          this.customerForm,
          "startTime",
          row.serviceBeginTime.substr(0, 10)
        );
        this.$set(
          this.customerForm,
          "endTime",
          row.serviceEndTime.substr(0, 10)
        );
      }
    },
    close() {
      this.visible = false;
    },
  },
};
</script>
<style lang="stylus" scoped>
/deep/ .el-input{
  width 90% !important
}
/deep/ .el-select{
  width 90% !important
}
/deep/ .el-select .el-input{
  width 100% !important
}
/deep/ .el-range-editor.el-input__inner{
  width 102% !important
}
</style>
