<template>
  <div class="site_list_box padding-md text-white">
    <div v-if="$route.path.lastIndexOf('/') === 0">
      <!-- 搜索框 -->
      <div class="box-card">
        <div class="text item">
          <el-form inline class="padding-top margin-sm-bottom">
            <el-form-item :label="$t('siteList.siteId')">
              <el-input
                :placeholder="$t('siteList.inputSiteId')"
                v-model="searchForm.siteId"
                style="width: 250px"
                clearable
              />
            </el-form-item>
            <el-form-item :label="$t('siteList.siteName')">
              <el-input
                :placeholder="$t('siteList.inputSiteName')"
                v-model="searchForm.name"
                style="width: 250px"
                clearable
              />
            </el-form-item>
            <el-form-item>
              <el-button
                class="btn-purple"
                icon="el-icon-search"
                :disabled="role >= 1 ? true : false"
                @click="querySite()"
                v-preventReClick
                >{{ $t("siteList.checkButton") }}</el-button
              >
              <el-button
                v-auth="btnAuth.add"
                class="btn-green"
                icon="el-icon-plus"
                @click="onAdd(1)"
                >{{ $t("deviceList.addEquipment") }}</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 站点数据信息展示表格 -->
      <div class="table-box">
        <el-table
          :data="siteData"
          style="width: 100%"
          :cell-style="{ 'text-align': 'center' }"
          :header-cell-style="{ 'text-align': 'center' }"
          :row-class-name="tableRowClassName"
          :row-style="{ height: '60px' }"
        >
          <el-table-column
            :label="$t('siteList.siteId')"
            prop="id"
            min-width="200"
          >
            <template v-slot="{ row }">
              <div @click="toDevice(row)" style="cursor: pointer">
                {{ row.siteId }}
              </div>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('siteList.siteName')"
            prop="name"
            min-width="100"
          >
          </el-table-column>
          <!-- <el-table-column label="客户名称" prop="customerName">
          </el-table-column>
          <el-table-column label="投资比例" prop="ratio"> </el-table-column> -->
          <el-table-column
            :label="$t('siteList.siteCondition')"
            prop="online"
            min-width="100"
          >
            <template slot-scope="scope">
              <span
                v-if="
                  scope.row.online == '在线' || scope.row.online == 'Online'
                "
                style="color: #38b862"
              >
                <span
                  ><icon
                    type="icon-xinhao"
                    color="#38b862"
                    size="18"
                    style="display: inline"
                  ></icon
                ></span>
              </span>
              <span
                v-else-if="
                  scope.row.online == '离线' || scope.row.online == 'Offline'
                "
                style="color: #234659"
              >
                <icon
                  type="icon-xinhao"
                  color="#234659"
                  size="18"
                  style="display: inline"
                ></icon>
              </span>
            </template>
          </el-table-column>
          <el-table-column label="运行状态" prop="status" min-width="100">
            <template slot-scope="scope">
              <span
                v-if="
                  scope.row.status == '运行' || scope.row.status == 'Operation'
                "
                style="color: #38b862"
              >
                <icon
                  type="icon-dian1"
                  color="#38b862"
                  size="8"
                  style="display: inline"
                ></icon>
                <span style="margin-left: 5px; margin-top: 5px">{{
                  scope.row.status
                }}</span>
              </span>
              <span
                v-else-if="
                  scope.row.status == '停机' ||
                  scope.row.status == 'Stop' ||
                  scope.row.status == '空闲'
                "
                style="color: #7933c3"
              >
                <icon
                  type="icon-dian1"
                  color="#7933c3"
                  size="8"
                  style="display: inline"
                ></icon>
                <span style="margin-left: 5px; margin-top: 5px">{{
                  scope.row.status
                }}</span>
              </span>
              <span
                v-else-if="
                  scope.row.status == '故障' || scope.row.status == 'Break-down'
                "
                style="color: #de4542"
              >
                <icon
                  type="icon-dian1"
                  color="#de4542"
                  size="8"
                  style="display: inline"
                ></icon>
                <span style="margin-left: 5px; margin-top: 5px">{{
                  scope.row.status
                }}</span>
              </span>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('siteList.cumulativeCharge')"
            prop="totalChargeNum"
            min-width="160"
          >
            <template slot-scope="scope">
              {{ scope.row.totalChargeNum | num }}
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('siteList.cumulativeDischarge')"
            prop="totalDischargeNum"
            min-width="160"
          >
            <template slot-scope="scope">
              {{ scope.row.totalDischargeNum | num }}
            </template>
          </el-table-column>
          <el-table-column label="费率标准" prop="rateName" min-width="120">
          </el-table-column>
          <el-table-column label="投资方式" prop="allotWay" min-width="100">
            <template slot-scope="scope">
              <span v-if="scope.row.allotWay === 1">
                {{ "电价折扣" }}
              </span>
              <span v-else-if="scope.row.allotWay === 2">
                {{ "投资分红" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            label="数据更新时间"
            prop="updateTime"
            min-width="160"
          >
          </el-table-column>
          <el-table-column :label="$t('deviceList.operation')" min-width="100">
            <!-- 操作按钮 -->
            <template v-slot="{ row }">
              <div class="flex items-middle space-around">
                <el-button
                  class="text-red"
                  type="text"
                  v-auth="btnAuth.issued"
                  @click="IssuedBtn(row)"
                  size="mini"
                >
                  <icon
                    type="iconfont icon-shezhi03-F"
                    color="#234659"
                    size="28"
                  ></icon
                ></el-button>
                <el-button
                  class="text-red"
                  type="text"
                  @click="onAdd(2, row)"
                  v-auth="btnAuth.edit"
                  size="mini"
                >
                  <icon
                    type="icon-wendang-caogao-F"
                    color="#234659"
                    size="25"
                  ></icon
                ></el-button>
                <el-button
                  class="text-red"
                  type="text"
                  @click="onAddc(2, row)"
                  v-auth="btnAuth.editc"
                  size="mini"
                >
                  <icon
                    type="icon-wendang-caogao-F"
                    color="#234659"
                    size="25"
                  ></icon
                ></el-button>
                <el-button
                  class="text-red"
                  type="text"
                  @click="onDelete(row)"
                  v-auth="btnAuth.delete"
                  size="mini"
                >
                  <icon type="icon-shanchu1-F" color="#234659" size="25"></icon
                ></el-button>
                <!-- <el-button
                  class="text-red"
                  type="text"
                  @click="onDelete(row)"
                  icon="el-icon-delete"
                  v-auth="btnAuth.delete"
                  size="mini"
                  >{{ $t("deviceList.deleteDevice") }}</el-button
                >
                <el-button
                  class="text-blue"
                  type="text"
                  @click="onAdd(2, row)"
                  icon="el-icon-edit"
                  v-auth="btnAuth.edit"
                  size="mini"
                  >{{ $t("deviceList.editInfo") }}</el-button
                > -->
                <!-- <div>
                  <i class="el-icon-view"></i>
                  <el-button
                    class="text-blue"
                    type="text"
                    @click="onAdd(3, row)"
                    >查看</el-button
                  >
                </div> -->
              </div>
            </template>
          </el-table-column>
        </el-table>
        <!-- 翻页 -->
        <div class="block" style="margin-top: 15px">
          <pagination
            :total="countNum"
            v-on:getCurrent="changeCurrent"
            v-on:getPageSize="changePage"
          ></pagination>
        </div>
      </div>
    </div>
    <site-update ref="siteUpdate" @refresh="getSite"></site-update>
    <c-update ref="cUpdate"></c-update>
    <command-issued ref="commandIssued"></command-issued>
    <!-- <router-view v-else></router-view> -->
  </div>
</template>

<script>
import pagination from "../../components/pagination.vue";
import { siteQuery, siteDel } from "../../api/siteList";
import SiteUpdate from "./components/siteUpdate.vue";
// import DeviceInfo from "../../components/deviceInfo.vue";
import i18n from "@/lang";
import CUpdate from "./components/cUpdate.vue";
import CommandIssued from "./components/issued.vue";
export default {
  components: { pagination, SiteUpdate, CUpdate, CommandIssued },
  data() {
    return {
      searchForm: {
        siteId: "",
        name: "",
      },
      siteData: [],
      searchRules: {
        siteId: [
          { required: false, message: "请输入站点ID", trigger: "blur" },
          {
            pattern: /^[a-zA-Z][a-zA-Z0-9_]*$/,
            message: "用户ID以字母开头，且只能包含英文字母、数字、下划线",
          },
        ],
      },
      object: {
        pageNumber: 1,
        pageSize: 10,
      },
      currentPage: 1, // 当前页码
      pageSize: 10, // 每页的数据条数
      role: 0, //角色
      countNum: 0,
      btnAuth: {
        add: "siteAdd",
        delete: "siteDelete",
        edit: "siteEdit",
        detail: "siteDetail",
        editc: "siteCustomerEdit",
        issued: "issued",
      },
    };
  },
  mounted() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getSite();
    },
    getSite() {
      // console.log(obj);
      siteQuery(this.object)
        .then((res) => {
          console.log(res.data.data.list);
          this.countNum = res.data.data.countNum;
          this.siteData = res.data.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    querySite() {
      let obj = {
        pageNumber: this.object.pageNumber,
        pageSize: this.object.pageSize,
        para: {
          siteId: this.searchForm.siteId,
          name: this.searchForm.name,
        },
      };
      siteQuery(obj).then((res) => {
        if (res.data.data.list.length == 0) {
          this.$message({
            type: "warning",
            message: "暂无结果",
          });
        } else {
          this.countNum = res.data.data.countNum;
          this.siteData = res.data.data.list;
        }
      });
    },
    onAdd(type, row = {}) {
      this.$refs.siteUpdate.open(type, row);
    },
    onAddc(type, row = {}) {
      this.$refs.cUpdate.open(type, row);
    },
    IssuedBtn(row) {
      this.$refs.commandIssued.open(row);
    },
    onDelete(row) {
      console.log(row.id);
      this.$confirm(
        i18n.t("prompt.deletePromptInfo"),
        i18n.t("prompt.promptInfo"),
        {
          confirmButtonText: i18n.t("prompt.confirm"),
          cancelButtonText: i18n.t("prompt.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          siteDel({ id: row.id }).then((res) => {
            if (res.data.code == 200) {
              this.$message({
                type: "success",
                message: i18n.t("prompt.operateSuccessfully"),
              });
              this.getSite();
            } else {
              this.$message({
                type: "error",
                message: i18n.t("prompt.operationFailure") + res.data.message,
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: i18n.t("prompt.OperationCancelled"),
          });
        });
    },
    toDevice(row) {
      // console.log(id);
      this.$router.push({
        name: "SiteDetail",
        query: {
          id: row.siteId,
          rateId: row.rateId,
          siteName: row.name,
        },
      });
    },
    changeCurrent(data) {
      this.object.pageNumber = data;
      this.currentPage = data;
      console.log(this.object);
      this.getSite();
    },
    changePage(data) {
      this.object.pageSize = data;
      this.pageSize = data;
      console.log(this.object);
      this.getSite();
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 0) {
        return "";
      } else {
        return "warning-row";
      }
    },
  },
  filters: {
    num: (val, fix = 2) => {
      if (val < 0) {
        val = parseFloat(val);
        val = val.toFixed(fix); // 保留小数2位
        val = Math.abs(val);
        val = "" + val; // 转换成字符串
        console.log("llllll", val);
        var int = val.slice(0, fix * -1 - 1); // 拿到整数
        var ext = val.slice(fix * -1 - 1); // 获取到小数
        int = int.split("").reverse().join(""); // 翻转整数
        var temp = ""; // 临时变量
        for (var i = 0; i < int.length; i++) {
          temp += int[i];
          if ((i + 1) % 3 == 0 && i != int.length - 1) {
            temp += ","; // 每隔三个数字拼接一个逗号
          }
        }
        temp = temp.split("").reverse().join(""); // 加完逗号之后翻转
        temp = temp + ext; // 整数小数拼接
        return "-" + temp; // 返回
      } else if (val != "-" && val != "" && val != null && val > 0) {
        val = parseFloat(val);
        val = val.toFixed(fix); // 保留小数2位
        val = "" + val; // 转换成字符串
        int = val.slice(0, fix * -1 - 1); // 拿到整数
        ext = val.slice(fix * -1 - 1); // 获取到小数
        int = int.split("").reverse().join(""); // 翻转整数
        temp = ""; // 临时变量
        for (i = 0; i < int.length; i++) {
          temp += int[i];
          if ((i + 1) % 3 == 0 && i != int.length - 1) {
            temp += ","; // 每隔三个数字拼接一个逗号
          }
        }
        temp = temp.split("").reverse().join(""); // 加完逗号之后翻转
        temp = temp + ext; // 整数小数拼接
        return temp; // 返回
      } else if (val == 0 || val == "0") {
        return val;
      } else {
        return "-";
      }
    },
  },
};
</script>

<style lang="stylus" scoped>
.site_list_box{
    width: 100%;
    box-sizing: border-box;
    height: calc(100vh - 130px);
    overflow: auto;
}
.table-box{
  padding: 20px;
    // background: linear-gradient(45deg, #35478a, #162257);
    background: #102135
    border-radius: 10px;
}
/deep/.el-table, .el-table tr, .el-table td, .el-table th {
    background-color: transparent!important;
}
</style>
